import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import GetStarted from "../components/CTA/GetStarted"
import MarkdownViewer from "@components/MarkdownViewer"
import ImageMeta from "../components/ImageMeta"
import Seamless from "../components/Seamless"
import CTA from "../components/CTA/CTA"

const Lifestyle = ({ data, pageContext, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const patients = data.allLifestylePagesJson.nodes
  const language = pageContext.language

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
        />

        <div className="lifestyle-page">
          <div className="columns">
            <div className="column is-4"></div>
            <div className="column">
              <h1 className="has-text-centered">{post.heading}</h1>
              <MarkdownViewer markdown={post.blurb} />
              <div className="lifestyle-patients">
                {patients.map((item, i) => (
                  <div key={i} className="lifestyle-patient">
                    <Link to={`/${item.title}`}>
                      <ImageMeta
                        cloudName="nuvolum"
                        publicId={item.homepageImage}
                        width="auto"
                        className="theater-image"
                      />
                    </Link>
                    <div className="lifestyle-patient-bottom-section">
                      <span className="lifestyle-patient-bottom-name">
                        {item.patientName}
                      </span>
                      <Link
                        className="lifestyle-patient-bottom-link"
                        to={`/${item.title}`}
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="column is-4"></div>
          </div>

          <div className="lifestyle-cta color-back">
            <GetStarted
              sideColumnIs={5}
              centerHeading
              centerText
              heading={post.getStarted.heading}
              buttons={post.getStarted.buttons}
              paragraph={<p>{post.getStarted.blurb}</p>}
            />
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query LifestylePage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        blurb
        getStarted {
          heading
          blurb
          buttons {
            button {
              buttonText
              appearance
              href
              destination
            }
          }
        }
      }
    }
    allLifestylePagesJson {
      nodes {
        title
        patientName
        homepageImage
      }
    }
  }
`

export default Lifestyle
